@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes rotate {
  from {transform: rotateX(0deg);}
  to {transform: rotateX(360deg);}
}

.App {
  text-align: center;
  background-color: #FFF;
  font-family: "Noto Sans", sans-serif;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}

.App-logo {
  height: 17.5vmin;
  animation: rotate 15s infinite linear;
  margin-bottom: 30px;
}

.App-services {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
}

@media only screen and (max-width: 960px) {
  .App-header {
    display: block;
    padding-top: 30vh;
  }
}

.App-link {
  color: #8C99C9;
}

footer {
  width: 100%;
  color: #000;
  position: fixed;
  bottom: 0;
  padding: 50px;
  font-size: 16px;
}

.App-companyNo {
  font-size: 15px;
}

footer ul {
  padding: 0;
  list-style: none;
  text-align: left;
}
